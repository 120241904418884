.toogleIndicatorCaret {
  font-size: 30px;
}

.toogleSubText {
  margin-top: -10px;
}

.toogleText {
  margin-top: -5px;
}
