.form-input-error {
    border-bottom-color: red !important;
    border-bottom-width: 2px !important;
}

label {
    font-size: 18px;
    padding-top: 10px;
}

.srv-validation-message {
    color: red;
    font-style: italic;
    margin-left: 5px;
    margin-bottom: -24px;
}

.form-control:focus {
    border-color: 0 0 0 0.2rem var(--secondary-color) !important;
    box-shadow: 0 0 0 0.1rem var(--secondary-color) !important;
}
