.tabs-wrapper {
  overflow: hidden;
}

.tabs-wrapper .tabs-container {
  white-space: nowrap;
  min-width: 570px;
}
.tabs-wrapper .tabs-container .tab {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  padding-bottom: 25px;
  color: #858c92;
  transition: color 200ms;
  cursor: pointer;
  margin-right: 30px;
}
.tabs-wrapper .tabs-container .tab:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
}
.tabs-wrapper .tabs-container .tab.active {
  color: #2c3444;
}
.tabs-wrapper .tabs-container .tab.active:after {
    background-color: #48a272;
}
.tabs-wrapper .tabs-container .tab:hover {
  text-decoration: underline;
}
.tabs-wrapper .tabs-container .tab.active:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .tabs-wrapper {
    overflow: auto;
  }
}
