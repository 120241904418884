@import '~bootstrap/scss/bootstrap.scss';

@include media-breakpoint-down(sm) {
  .text-label {
    display: none;
  }
}

.step-complete {
  fill: var(--secondary-color)
}

.step-incomplete {
  fill: #868c91;
  cursor: default !important;
  text-decoration: none !important;
}

.btn:focus {
  box-shadow: none !important;
}