.editForm {
  font-size: 14px;
  color: #2c3444;
}

.editFormRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #e9edf1;
}

.editFormRow .valueBox {
  width: 80px;
  margin-left: 15px;
}
.selectContainer-wrapper {
  padding-bottom: 10px;
  margin-bottom: 1rem;
}

.editFormRow input[type='checkbox']:disabled {
  opacity: 0.3;
  cursor: unset;
}

.editFormRow .lockButton {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 17px;
  color: #858c92;
  margin-right: 10px;
  margin-left: 10px;
}

.editFormRow .lockButton .material-icon {
  cursor: pointer;
}

.editFormRow .lockButtonChecked {
  color: #48a272; /* var(--primary-color); */
  cursor: pointer;
}

.editFormRow .hideLock {
  color: transparent;
  pointer-events: none;
}

.editFormRow input:disabled {
  background-color: rgba(195, 198, 201, 0.2);
}
