.listItem {
  opacity: 1;
  transition: all 0.2s;
}

.listItemDraggingOver {
  scale: 1.02;
}

.listItemDragging {
  opacity: 0.5;
}
