.App {
  height: 100%;
}

/* Icons */
.material-icons {
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  font-weight: normal;
  font-style: normal;
  font-size: 130%;
  line-height: inherit;
  vertical-align: text-top;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
.material-icons.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
