.Wrapper {
  display: inline-block;
  position: relative;
  margin-left: 10px;
}
.tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  background-color: #17253f;
  font-size: 14px;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  padding: 10px;
  min-width: 180px;
}
.top {
  top: -30px;
}

.right {
  left: calc(100% + 5px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.bottom {
  bottom: -30px;
}
.left {
  left: auto;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
