@import "~bootstrap/scss/bootstrap.scss";

.page-header-title {
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}
