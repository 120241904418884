.css-t3ipsp-control,
.css-t3ipsp-control:hover {
  border-color: 0 0 0 0.2rem var(--secondary-color) !important;
  box-shadow: 0 0 0 0.1rem var(--secondary-color) !important;
}

.css-1nmdiq5-menu {
  z-index: 999 !important;
}

.survey-multiselect input:focus,
.survey-multiselect textarea:focus {
  outline: none;
  border: none !important;
}