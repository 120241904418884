$theme-colors: (
  "primary": #8dc63f,
  "secondary": #868c91,
  "muted": #a4a8ac
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.container {
  max-width: 960px;
}

// custom input box
input:focus, textarea:focus {
  outline: none;
  border: 1px solid #2C3444 !important;
}
// custom checkbox
input[type="checkbox"] {
  position: relative;
  width: 20px;
  height: 20px;
  color: #2C3444;
  border: 1px solid #2C3444;
  border-radius: 2px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 3px;
    left: 6px;
    width: 6px;
    height: 10px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: #fff;
    background: #213357;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}
// custom input box
input[type="text"], input[type="number"] {
  border: 1px solid #E9EDF1;
  border-radius: 2px;
  background-color: #FFFFFF;
  padding: 8px;
}

//**** Select fields ****//
.selectContainer {
  position: relative;
  border: 1px solid #E9EDF1;
  border-radius: 2px;
  background-color: #FFFFFF;
  padding: 0px;
  font-size: 14px;
  color: #2C3444;

  &:after {
    /* down arrow */
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 0;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2C3444;
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}

.selectDisabled {
  opacity: 0.5;
}

select {
  /* remove all default styling */
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // -ms-appearance: none;
  // -o-appearance: none;
  // appearance: none;
  box-sizing: border-box;
  background: transparent;
  border: none;
  position: relative;
  z-index: 10;
  cursor: pointer;
  width: 100%;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 35px !important;

  &:focus {
    outline: none;
  }
}

@media (max-width: 767px) {
  nav.navbar.navbar-expand-md.survey-nav img {
    margin: 0;
  }
  nav.navbar.navbar-expand-md.survey-nav h3 {
    width: calc(100% - 120px);
    font-size: 18px;
    padding-left: 8px;
    line-height: 20px;
  }
  .mobile-scroll {
    overflow: auto;
  }
}
