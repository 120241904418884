.syncContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 10000;
    background-color: rgba(255,255,255, 0.3);
    span{
        margin: 10px;
    };
}

.hide {
    display: none;
}