.table .row {
  margin-left: 0;
  margin-right: 0;
}

.table [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.text-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}