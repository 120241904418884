.networkContainer {
    display: flex;
    width: 100%;
    max-height: 120px;
    transition: all 0.5s ease-out;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    padding: 15px;
    text-align: center;
}

.connected {
    background: #48A272;
}

.disconnected {
    background: #E05263;
}

.networkContainerHide {
    height: 0px;
    padding: 0px;
}