.survey-container, .survey-layout {
  height: 100%;
}

.loadingContainer{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span{
      margin: 10px;
  }
}