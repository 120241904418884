.export {
  padding-bottom: 1rem;
  text-align: center;
}

#download-report {
  cursor: pointer;
}

#email-report {
  cursor: pointer;
}